// setFlags

import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import axios from 'axios'
import Mint from 'mint-ui'
import qs from 'qs'
import 'element-ui/lib/theme-chalk/index.css'
import {Message} from 'element-ui'
import JSEncrypt from 'jsencrypt'
import AlloyFinger from 'alloyfinger'
import AlloyFingerPlugin from 'alloyfinger/vue/alloy_finger_vue'

import videoPlayer from "vue-video-player";
import "video.js/dist/video-js.css";
import "vue-video-player/src/custom-theme.css";

Vue.config.productionTip = false

Vue.use(ElementUI)
Vue.use(videoPlayer)
Vue.use(AlloyFingerPlugin, {
  AlloyFinger
})
Vue.use(Mint)

window.eventBus = new Vue()

Vue.prototype.$axios = axios
Vue.prototype.$qs = qs
Vue.prototype.$message = Message

// 发起请求，打开loading   请求拦截器
// axios.interceptors.request.use((config) => {
//   Indicator.open({
//     text: 'Loading...',
//     spinnerType: 'fading-circle'
//   });
//   return config
// },(err) => {
//   return Promise.reject(err)
// })
// // 响应回来关闭loading   响应拦截器
// axios.interceptors.response.use((response) => {
//   Mint.Indicator.close() // 关闭loading
//   return response
// },(err) => {
//   return Promise.reject(err)
// })

// Vue.prototype.$getRsaCode = (str) => { // 注册方法

//   let pubKey = `-----BEGIN PUBLIC KEY-----${PUBLIC_KEY}-----END PUBLIC KEY-----` // ES6 模板字符串 引用 rsa 公钥

//   let encryptStr = new JSEncrypt()

//   encryptStr.setPublicKey(pubKey) // 设置 加密公钥

//   let data = encryptStr.encrypt(str.toString()) // 进行加密

//   return data

// }

//本地开发
// Vue.prototype.baseUrl = 'http://192.168.3.158:17088'

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
