import Vue from 'vue'
import VueRouter from 'vue-router'
// import Login from '../views/Login.vue'
// import Index from '../views/Index.vue'
// import Register from '../views/Register.vue'
// import Index2 from '../views/Index2.vue'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


const routes = [
  { 
    path: '/',
    name: 'Login',
    // component: Login
    component: () => import('../views/Login.vue'),
    meta: {
      title: '登录'
    },  
  },
  {
    path: '/register',
    name: 'Register',
    // component: Register
    component: () => import('../views/Register.vue'),
    meta: {
      title: '注册'
    },
  },
  {
    path: '/index',
    name: 'Index',
    // component: Register
    component: () => import('../views/Index.vue'),
    meta: {
      title: '首页'
    },
    children: [
      {
        path: '/lookcasedetail',
        name: 'LookCaseDetail',
        component: () => import('../views/LookCaseDetail.vue'),
        meta: {
          title: '案件详情'
        },
      },
    ],
  },
  
  {
    path: '/index2',
    name: 'Index2',
    // component: Index2,
    component: () => import('../views/Index2.vue'),
    meta: {
      title: '首页'
    },
    children: [
      {
        path: '/casedetail',
        name: 'CaseDetail',
        component: () => import('../views/CaseDetail.vue'),
        meta: {
          title: '案件详情'
        },
        children: [
          {
            path: '/casedetail/scheme',
            name: 'Scheme',
            component: () => import('../views/Scheme.vue'),
            meta: {
              title: '调解方案'
            },
          },
        ],
      },
      {
        path: '/arbitratdetail',
        name: 'ArbitratDetail',
        component: () => import('../views/ArbitratDetail.vue'),
        meta: {
          title: '仲裁详情'
        },
      },

    ],
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})


export default router
